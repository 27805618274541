import React from 'react'
import Login from '../../components/welcome/Login'

export default function LoginPage() {
  return (
    <>
    <Login/>
    </>
  )
}
