import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const phoneUtil = PhoneNumberUtil.getInstance();
const BASE_URL = process.env.REACT_APP_API_URL;

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
export default function PhoneModal({
  showPhone,
  setShowPhone,
  setPhoneError,
  editDate,
  setEditData,
  handleSubmit,
  control,
  reset,
  errors,
  getProfileData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isValid = isPhoneValid(editDate.phone);

  const handlePhoneClose = () => {
    setShowPhone(false);
    reset();
  };

  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    if (isValid) {
      let body = data;
      setIsLoading(true);
      try {
        const response = await axios.put(
          `${BASE_URL}/auth/update-profile`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data?.success === 1) {
          toast.success(response?.data?.message);
          getProfileData();
          setShowPhone(false);
        }
      } catch (error) {
        if (error?.response?.data) {
          if (
            error.response.data.message === "Session expired please login again"
          ) {
            // Handle session expiration
            localStorage.removeItem("token");
            navigate("/");
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error?.response?.data?.message);
          }
        } else {
          toast.error("Something went wrong");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal centered show={showPhone} onHide={handlePhoneClose}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <p className="fw-semibold">Phone *</p>
            <div className="">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    defaultCountry="us"
                    value={editDate.phone}
                    placeholder="Phone number"
                    onChange={(e) => {
                      field.onChange(e);
                      setEditData({ ...editDate, phone: e });
                    }}
                    onBlur={field.onBlur}
                  />
                )}
              />
            </div>
            {!isValid && (
              <p className="text-danger py-2 mb-0">Phone is not valid</p>
            )}
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              type="submit"
              className="text-white btn"
              style={{ backgroundColor: "#336BA6" }}
            >
              Save
              {isLoading && (
                <div
                  className="spinner-border spinner-border-sm ms-3"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
