import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RightArrow from "../../assets/right-arrow.png";
import Graph from "./Graph";
import BackArrow from "../../assets/back-button.png";

import dayjs from "dayjs";
import LeftArrow from "../../assets/left-arrow.png";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Notification from "../../assets/profile-img.png";
import Navbar from "../common/Navbar";

const BASE_URL = process.env.REACT_APP_API_URL;

export default function GraphData() {
  const navigate = useNavigate();
  const today = dayjs();
  const formattedToday = today.format("YYYY-MM-DD");
  // const [value, setValue] = useState(dayjs(formattedToday));
  const [valueDayGraph, setValueDayGraph] = useState(dayjs(formattedToday));
  const [graphDayData, setGraphDayData] = useState(null);
  const [valueMonthGraph, setValueMonthGraph] = useState(dayjs(formattedToday));
  const [graphMonthData, setGraphMonthData] = useState(null);
  const [valueYearGraph, setValueYearGraph] = useState(dayjs(formattedToday));
  const [graphYearData, setGraphYearData] = useState(null);
  const [notificationData, setNotificationData] = useState(null);

  const handleGraphLeftArrow = () => {
    const newGraphValue = valueDayGraph.subtract(1, "day");
    setValueDayGraph(newGraphValue);
  };

  const handleGraphRightArrow = () => {
    const newGraphValue = valueDayGraph.add(1, "day");
    setValueDayGraph(newGraphValue);
  };

  const handleGraphMonthLeftArrow = () => {
    const newGraphValue = valueMonthGraph.subtract(1, "month");
    setValueMonthGraph(newGraphValue);
  };

  const handleGraphMonthRightArrow = () => {
    const newGraphValue = valueMonthGraph.add(1, "month");
    setValueMonthGraph(newGraphValue);
  };

  const handleGraphYearLeftArrow = () => {
    const newGraphValue = valueYearGraph.subtract(1, "year");
    setValueYearGraph(newGraphValue);
  };

  const handleGraphYearRightArrow = () => {
    const newGraphValue = valueYearGraph.add(1, "year");
    setValueYearGraph(newGraphValue);
  };

  useEffect(() => {
    const getDayGraphData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${BASE_URL}/filtered-meetings/${valueDayGraph.format("MM-DD-YYYY")}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data) {
          let transformGraphDaydata = response?.data?.counts?.day?.map((day) => {
            return {
              value: day?.count,
              name: day?.name,
            };
          });

          let letcolors = response?.data?.counts?.day?.map((day) => {
            return day?.color;
          });
          setGraphDayData({ data: transformGraphDaydata, colors: letcolors });
          toast.success(response?.data?.message);
        }
      } catch (error) {
        if (error?.response?.data) {
          if (
            error.response.data.message === "Session expired please login again"
          ) {
            // Handle session expiration
            localStorage.removeItem("token");
            navigate("/");
            // toast.error(error?.response?.data?.message);
          } else {
            // toast.error(error?.response?.data?.message);
          }
        } else {
          // toast.error("Something went wrong");
        }
      }
    };
    getDayGraphData();
  }, [valueDayGraph, navigate]);

  useEffect(() => {
    const getMonthGraphData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${BASE_URL}/filtered-meetings/${valueMonthGraph.format(
            "MM-DD-YYYY"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data) {
          let transformGraphMonthdata = response?.data?.counts?.month?.map((month) => {
            return {
              value: month?.count,
              name: month?.name,
            };
          });

          let letcolors = response?.data?.counts?.month?.map((month) => {
            return month?.color;
          });
          setGraphMonthData({
            data: transformGraphMonthdata,
            colors: letcolors,
          });
          toast.success(response?.data?.message);
        }
      } catch (error) {
        if (error?.response?.data) {
          if (
            error.response.data.message === "Session expired please login again"
          ) {
            // Handle session expiration
            localStorage.removeItem("token");
            navigate("/");
            // toast.error(error?.response?.data?.message);
          } else {
            // toast.error(error?.response?.data?.message);
          }
          // toast.error(error?.response?.data?.error);
        } else {
          // toast.error("Something went wrong");
        }
      }
    };
    getMonthGraphData();
  }, [valueMonthGraph, navigate]);

  useEffect(() => {
    const getYearGraphData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${BASE_URL}/filtered-meetings/${valueYearGraph.format(
            "MM-DD-YYYY"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data) {
          let transformYeardata = response?.data?.counts?.year?.map((year) => {
            return {
              value: year?.count,
              name: year?.name,
            };
          });

          let letcolors = response?.data?.counts?.year?.map((year) => {
            return year?.color;
          });
          setGraphYearData({
            data: transformYeardata,
            colors: letcolors,
          });
          toast.success(response?.data?.message);
        }
      } catch (error) {
        if (error?.response?.data) {
          if (
            error.response.data.message === "Session expired please login again"
          ) {
            // Handle session expiration
            localStorage.removeItem("token");
            navigate("/");
            // toast.error(error?.response?.data?.message);
          } else {
            // toast.error(error?.response?.data?.message);
          }
        } else {
          // toast.error("Something went wrong");
        }
      }
    };
    getYearGraphData();
  }, [valueYearGraph, navigate]);

  const getNotification = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/get-notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.success === 1) {
        setNotificationData(response?.data?.notifications);
        // toast.success(response?.data?.message);
        // getMeetingData();
        // getProfileData();
        // setShowCity(false);
      }
    } catch (error) {
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          // toast.error(error?.response?.data?.message);
        } else {
          // toast.error(error?.response?.data?.message);
        }
      } else {
        // toast.error("Something went wrong");
      }
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navbar
      // handleSearch={handleSearch}
      // searchText={searchText}
      // value={value}
      />
      <div className="container-fluid px-3 py-3">
        <div>
          <Link to="/dashboard">
            <img alt="" src={BackArrow} />
          </Link>
        </div>
        <h4 className="my-3 ms-5 ps-5 text-center">All Statistics</h4>
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="border rounded mt-3 notification">
              <div
                className="bs-parent px-3 py-2"
                style={{ minHeight: "40px", maxHeight: "40px" }}
              >
                <small className="fw-bold">Latest Update</small>
              </div>
              {notificationData && notificationData?.length > 0 ? (
                notificationData?.map((item) => {
                  return (
                    <div
                      key={item?._id}
                      className="d-flex align-items-center p-2"
                    >
                      <span className="px-2">
                        <img
                          className="rounded-circle"
                          style={{ width: "51px", height: "51px" }}
                          src={item?.profilePicture || Notification}
                          alt=""
                        />
                      </span>
                      <span>
                        <p className="mb-0 fw-bold">{item?.detail}</p>
                        <p className="mb-0">{item?.time}</p>
                      </span>
                    </div>
                  );
                })
              ) : (
                <div className="d-flex  align-items-center p-2">
                  <span>
                    <p className="mb-0 fw-bold">No Update Found</p>
                  </span>
                </div>
              )}
            </div>
          </div>
         
          <div className="col-lg-3 col-sm-6 mt-3">
            <div className="border rounded">
              <div
                className="bs-parent mx-3 text-center py-2"
                style={{ minHeight: "40px", maxHeight: "40px" }}
              >
                <span className="mx-3 pointer" onClick={handleGraphLeftArrow}>
                  <img alt="" src={LeftArrow} />
                </span>

                <small className="fw-bold">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="customDayPicker"
                      views={["day", "month", "year"]}
                      value={valueDayGraph}
                      onChange={(newValue) => setValueDayGraph(newValue)}
                      format="DD MMMM YYYY"
                    />
                  </LocalizationProvider>
                  {/* {valueDayGraph.format("DD MMMM YYYY")} */}
                </small>
                <span className="mx-3 pointer" onClick={handleGraphRightArrow}>
                  <img alt="" src={RightArrow} />
                </span>
              </div>
              <Graph
                DayData={graphDayData?.data}
                Daycolor={graphDayData?.colors}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-6  p-3">
            <div className="border rounded">
              <div
                className="bs-parent mx-4 text-center py-2 "
                style={{ minHeight: "40px", maxHeight: "40px" }}
              >
                <span
                  className="mx-3 pointer"
                  onClick={handleGraphMonthLeftArrow}
                >
                  <img alt="" src={LeftArrow} />
                </span>
                <small className="fw-bold">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="customMonthPicker"
                      views={["month", "year"]}
                      value={valueMonthGraph}
                      onChange={(newValue) => setValueMonthGraph(newValue)}
                    />
                  </LocalizationProvider>
                  {/* {valueMonthGraph.format("MMMM YYYY")} */}
                </small>
                <span
                  className="mx-3 pointer"
                  onClick={handleGraphMonthRightArrow}
                >
                  <img alt="" src={RightArrow} />
                </span>
              </div>

              <Graph
                DayData={graphMonthData?.data}
                Daycolor={graphMonthData?.colors}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-6  p-3">
            <div className="border rounded">
              <div
                className="bs-parent mx-4 text-center py-2 "
                style={{ minHeight: "40px", maxHeight: "40px" }}
              >
                <span
                  className="mx-3 pointer"
                  onClick={handleGraphYearLeftArrow}
                >
                  <img alt="" src={LeftArrow} />
                </span>
                <small className="fw-bold">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="customYearPicker"
                      views={["year"]}
                      value={valueYearGraph}
                      onChange={(newValue) => setValueYearGraph(newValue)}
                    />
                  </LocalizationProvider>
                </small>
                <span
                  className="mx-3 pointer"
                  onClick={handleGraphYearRightArrow}
                >
                  <img alt="" src={RightArrow} />
                </span>
              </div>
              <Graph
                DayData={graphYearData?.data}
                Daycolor={graphYearData?.colors}
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
        <div className="col-lg-3 col-sm-6 ">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                <DateCalendar
                  className="date-calender"
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div> */}
        {/* <div className="col-lg-3 col-sm-6 ">
            <div className="border rounded mt-4 notification">
              <div
                className="bs-parent px-3 py-2"
                style={{ minHeight: "40px", maxHeight: "40px" }}
              >
                <small className="fw-bold">Latest Update</small>
              </div>
              {notificationData && notificationData?.length > 0 ? (
                notificationData?.map((item) => {
                  return (
                    <div
                      key={item?._id}
                      className="d-flex align-items-center p-2"
                    >
                      <span className="px-2">
                        <img
                          className="rounded-circle"
                          style={{ width: "51px", height: "51px" }}
                          src={item?.profilePicture || Notification}
                          alt=""
                        />
                      </span>
                      <span>
                        <p className="mb-0 fw-bold">{item?.detail}</p>
                        <p className="mb-0">{item?.time}</p>
                      </span>
                    </div>
                  );
                })
              ) : (
                <div className="d-flex  align-items-center p-2">
                  <span>
                    <p className="mb-0 fw-bold">No Update Found</p>
                  </span>
                </div>
              )}
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </>
  );
}
