import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller } from "react-hook-form";
import EyeIcon from "../../../assets/eye-blocked 1.svg";
import Eye from "../../../assets/eye.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_API_URL;

export default function PasswordModal({
  showPassword,
  setShowPassword,
  editDate,
  handleSubmit,
  control,
  reset,
  errors,
  getProfileData,
}) {
  const [EyeState, setEyeState] = useState(true);
  const [NewEyeState, setNEwEyeState] = useState(true);
  const [ConEyeState, setConEyeState] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const handleEye = () => {
    setEyeState(!EyeState);
  };
  const handleNewEye = () => {
    setNEwEyeState(!NewEyeState);
  };
  const handleConEye = () => {
    setConEyeState(!ConEyeState);
  };
  const handlePasswordClose = () => {
    setShowPassword(false);
    reset();
    setEyeState(true);
    setNEwEyeState(true);
    setConEyeState(true);
  };

  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    let body = {
      old_password: data?.password,
      new_password: data?.ConPassword,
    };
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/update-password`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.success === 1) {
        toast.success(response?.data?.message);
        getProfileData();
        handlePasswordClose();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        console.log(error);
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal centered show={showPassword} onHide={handlePasswordClose}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <p className="fw-semibold">Current Password</p>
            <div className="input-group pe-2 ps-1 border py-1 bg-white rounded">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <input
                    name={field.name}
                    type={EyeState ? "password" : "text"}
                    className="form-control input-boxshadow-none border-0"
                    placeholder="**********"
                    aria-describedby="inputGroupPrepend2"
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <div className="d-flex flex-column justify-content-center bg-white p-0">
                <span className="EyeIcon ps-2" onClick={handleEye}>
                  {EyeState ? (
                    <img className="" alt="" src={Eye} />
                  ) : (
                    <img className="" alt="" src={EyeIcon} />
                  )}
                </span>
              </div>
            </div>
            <p className="text-danger py-2 mb-0">{errors?.password?.message}</p>
            <p className="fw-semibold">New Password</p>
            <div className="input-group pe-2 ps-1 border py-1 bg-white rounded">
              <Controller
                name="NewPassword"
                control={control}
                render={({ field }) => (
                  <input
                    name={field.name}
                    type={NewEyeState ? "password" : "text"}
                    className="form-control input-boxshadow-none border-0"
                    placeholder="**********"
                    aria-describedby="inputGroupPrepend2"
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <div className="d-flex flex-column justify-content-center bg-white p-0">
                <span className="EyeIcon ps-2" onClick={handleNewEye}>
                  {NewEyeState ? (
                    <img className="" alt="" src={Eye} />
                  ) : (
                    <img className="" alt="" src={EyeIcon} />
                  )}
                </span>
              </div>
            </div>
            <p className="text-danger py-2 mb-0">
              {errors?.NewPassword?.message}
            </p>
            <p className="fw-semibold">Confirm Password</p>
            <div className="input-group pe-2 ps-1 border py-1 bg-white rounded">
              <Controller
                name="ConPassword"
                control={control}
                render={({ field }) => (
                  <input
                    name={field.name}
                    type={ConEyeState ? "password" : "text"}
                    className="form-control border-0 input-boxshadow-none"
                    placeholder="**********"
                    aria-describedby="inputGroupPrepend2"
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <div className="d-flex flex-column justify-content-center bg-white p-0">
                <span className="EyeIcon ps-2" onClick={handleConEye}>
                  {ConEyeState ? (
                    <img className="" alt="" src={Eye} />
                  ) : (
                    <img className="" alt="" src={EyeIcon} />
                  )}
                </span>
              </div>
            </div>
            <p className="text-danger py-2 mb-0">
              {errors?.ConPassword?.message}
            </p>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              type="submit"
              className="text-white btn"
              style={{ backgroundColor: "#336BA6" }}
              //   onClick={handlePasswordClose}
            >
              Save
              {isLoading && (
                <div
                  className="spinner-border spinner-border-sm ms-3"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
