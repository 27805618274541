import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";

export default function NoteModal({
  showNoteModal,
  onHide,
  handleSaveNote,
  agentId,
  meetingId,
  date,
  timeSlot,
  notee,
}) {
    // console.log("NNNNNNNNNNNNNNNN",notee);
    
  const [note, setNote] = useState(notee);
  const [error, setError] = useState("");
  useEffect(() => {
    setNote(notee);
  }, [notee]);

  const handleInputChange = (e) => {
    setNote(e.target.value);
  };

  const handleSave = () => {
    if (!note.trim()) { // Check if note is empty or contains only whitespace
      setError("Please enter a meeting note."); // Set error message
      return; // Stop execution if the note is empty
    }

    handleSaveNote(meetingId, note, agentId, timeSlot, date); // Call the save function from the parent
    setNote(""); // Clear the input field after saving
    onHide(); // Close the modal
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {  
      handleSave();
    }
  };

  return (
    <Modal show={showNoteModal} onHide={onHide} className="custom-modal">
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body className="custom-modal p-0 ps-3 pe-3">
        <p className="fw-semibold">Meeting Note *</p>
        <input
          type="text"
          value={note}
          maxLength="199"
          onChange={handleInputChange}
          onKeyDown={handleKeyDown} 
          className="form-control"
        />
        {error && <p className="text-danger mt-2">{error}</p>} 
      </Modal.Body>
      <Modal.Footer className="border-0">
        <button
          type="submit"
          className="text-white btn"
          style={{ backgroundColor: "#336BA6" }}
          onClick={handleSave}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
