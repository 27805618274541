// import logo from "./logo.svg";
import "./App.css";
import ChangePasswordPage from "./pages/change-password/ChangePasswordPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import ForgotPage from "./pages/forgot/ForgotPage";
import LoginErrorPage from "./pages/login-error/LoginErrorPage";
import MyProfilePage from "./pages/my-profile/MyProfilePage";
import OTPPage from "./pages/otp/OTPPage";
import PasswordUpdatePage from "./pages/password-update/PasswordUpdatePage";
import DetailsRegisteredUsersPage from "./pages/registered-users/DetailsRegisteredUsersPage";
import LoginPage from "./pages/welcome/LoginPage";
import { Route, Routes } from "react-router-dom";
import Protected from "./routes/Protected";
import Public from "./routes/Public";
import RoleProtected from "./routes/RoleProtected";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtpProtected from "./routes/OtpProtected";
import StatusPage from "./pages/status/StatusPage";
import GraphDataPage from "./pages/graph/GraphDataPage";

function App() {
  return (
    <>
      <Routes>
        <Route element={<Public />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login-error" element={<LoginErrorPage />} />
          <Route path="/forgot-password" element={<ForgotPage />} />
          <Route element={<OtpProtected />}>
            <Route path="/otp" element={<OTPPage />} />
            <Route path="/change-password" element={<ChangePasswordPage />} />
            <Route path="/update-password" element={<PasswordUpdatePage />} />
          </Route>
        </Route>
        <Route element={<Protected />}>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/graph" element={<GraphDataPage />} />
          <Route element={<RoleProtected allowedRoles={["Admin"]} />}>
            <Route
              path="/registered-users"
              element={<DetailsRegisteredUsersPage />}
            />
            <Route path="/status" element={<StatusPage />} />
          </Route>
          <Route path="/my-profile" element={<MyProfilePage />} />
        </Route>
      </Routes>
      <ToastContainer position="bottom-right" autoClose={1500} />
    </>
  );
}

export default App;
