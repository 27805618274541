import React from 'react'
import MyProfile from '../../components/my-profile/MyProfile'

export default function MyProfilePage() {
  return (
    <>
        <MyProfile/>
    </>
  )
}
