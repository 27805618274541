import React from "react";
import DetailsRegisteredUsers from "../../components/registered-users/DetailsRegisteredUsers";

export default function DetailsRegisteredUsersPage() {
  return (
    <>
      <DetailsRegisteredUsers />
    </>
  );
}
