import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./AuthProvider";

export default function OtpProtected() {
  const { otp } = useContext(AuthContext);

  if (!otp) {
    return <Navigate to="/forgot-password" />;
  }

  return <Outlet />;
}
