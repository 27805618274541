import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const BASE_URL = process.env.REACT_APP_API_URL;

export default function EditStatus({ editData, getStatusData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  const navigate = useNavigate();

  const handleNameClose = () => {
    setShowStatus(false);
    reset();
  };

  const schema = Yup.object({
    status: Yup.string()
      .max(20, "Status is not greater than 20 characters")
      .required("Status is required."),
    color: Yup.string().required("Color is required."),
    checkbox: Yup.boolean().required("Requried"),
  }).required();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      checkbox: editData?.is_status_active,
    },
  });

  const handleShow = () => {
    setValue("status", editData?.status_name);
    setValue("color", editData?.status_color);
    setValue("checkbox", editData?.is_status_active);
    setShowStatus(true);
  };

  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    let body = {
      status_id: editData?._id,
      status_name: data?.status,
      status_color: data?.color,
      is_status_active: data?.checkbox,
    };
    setIsLoading(true);
    try {
      const response = await axios.put(`${BASE_URL}/update-status`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.success === 1) {
        toast.success(response?.data?.message);
        getStatusData();
        setShowStatus(false);
      }
    } catch (error) {
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <li className="p-1 border-bottom " onClick={handleShow}>
        Edit
      </li>
      <Modal centered show={showStatus} onHide={handleNameClose}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <p className="fw-semibold">Edit Status</p>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  id="staus"
                  type="text"
                  autoComplete="off"
                  className="form-control px-2 input-boxshadow-none"
                  placeholder="Status"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  onBlur={field.onBlur}
                />
              )}
            />
            <p className="text-danger py-2 mb-0">{errors?.status?.message}</p>
            <p className=" py-2 mb-0 fw-semibold">Select Color</p>
            <Controller
              name="color"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  id="color"
                  type="color"
                  autoComplete="off"
                  className="form-control px-2 input-boxshadow-none"
                  style={{ height: "38px" }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  onBlur={field.onBlur}
                />
              )}
            />
            <p className="text-danger py-2 mb-0">{errors?.color?.message}</p>

            <div className="mt-4">
              <Controller
                name="checkbox"
                control={control}
                render={({ field }) => (
                  <input
                    className="form-check-input largerCheckbox"
                    type="checkbox"
                    {...field}
                    checked={field?.value}
                    id="invalidCheck2"
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <label
                className="form-check-label text-black ms-2"
                htmlFor="invalidCheck2"
                style={{ marginTop: "2px" }}
              >
                Active
              </label>
              <p className="text-danger py-2 mb-0">
                {errors?.checkbox?.message}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              type="submit"
              className="text-white btn"
              style={{ backgroundColor: "#336BA6" }}
            >
              Update
              {isLoading && (
                <div
                  className="spinner-border spinner-border-sm ms-3"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
