import React, { useContext, useState } from "react";
import banner from "../../assets/banner.png";
import logo from "../../assets/logo.png";
import Vector from "../../assets/Vector.png";
import EyeIcon from "../../assets/eye-blocked 1.svg";
import Eye from "../../assets/eye.svg";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { AuthContext } from "../../routes/AuthProvider";
const BASE_URL = process.env.REACT_APP_API_URL;

export default function ChangePassword() {
  const { email } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [EyeState, setEyeState] = useState(true);
  const [ConEyeState, setConEyeState] = useState(true);

  const schema = Yup.object({
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password must contain 8 or more characters"),
    ConPassword: Yup.string()
      .required("Confirm Password is required.")
      .min(8, "Confirm Password must contain 8 or more characters")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  }).required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const body = {
      email: email,
      new_password: data?.ConPassword,
    };
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/create-new-password`,
        body
      );
      if (response?.data?.success === 1) {
        toast.success(response?.data?.message);
        navigate("/update-password");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleEye = () => {
    setEyeState(!EyeState);
  };
  const handleConEye = () => {
    setConEyeState(!ConEyeState);
  };

  return (
    <>
      <div className="container-fluid login-main">
        <div className="row h-100">
          <div className="col-md-6 d-md-block d-none h-100 login-image">
            <img className="banner" alt="" src={banner} />
          </div>
          <div className="col-md-6  login-content text-center">
            <div className="content-main h-100 px-5 d-flex flex-column justify-content-center">
              <div>
                <img
                  className="content-main-logo img-fluid"
                  alt=""
                  src={logo}
                />
              </div>
              <h2 className="text-white">Create new Password</h2>
              <small className="text-white mb-2">
                Your new password must be different from previous password.
              </small>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="col-12 text-start text-white mb-3">
                    <label className="" htmlFor="password">
                      Password
                    </label>
                    <div className="input-group px-3 py-1 bg-white rounded">
                      <div className="d-flex flex-column justify-content-center bg-white p-0">
                        <span className="pe-2" id="vectorBorder">
                          <img className="" alt="" src={Vector} />
                        </span>
                      </div>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <input
                            type={EyeState ? "password" : "text"}
                            className="form-control"
                            id="password"
                            placeholder="**********"
                            aria-describedby="inputGroupPrepend2"
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            onBlur={field.onBlur}
                          />
                        )}
                      />
                      <div className="d-flex flex-column justify-content-center bg-white p-0">
                        <span className="EyeIcon ps-2" onClick={handleEye}>
                          {EyeState ? (
                            <img className="" alt="" src={Eye} />
                          ) : (
                            <img className="" alt="" src={EyeIcon} />
                          )}
                        </span>
                      </div>
                    </div>
                    <p
                      className="mb-0 text-danger mt-1"
                      style={{ height: "16px" }}
                    >
                      {errors?.password?.message}
                    </p>
                  </div>
                  <div className="col-12 text-start text-white mb-3">
                    <label className="" htmlFor="ConfirmPassword">
                      Confirm Password
                    </label>
                    <div className="input-group px-3 py-1 bg-white rounded">
                      <div className="d-flex flex-column justify-content-center bg-white p-0">
                        <span className="pe-2" id="vectorBorder">
                          <img className="" alt="" src={Vector} />
                        </span>
                      </div>
                      <Controller
                        name="ConPassword"
                        control={control}
                        render={({ field }) => (
                          <input
                            type={ConEyeState ? "password" : "text"}
                            className="form-control"
                            id="ConfirmPassword"
                            placeholder="**********"
                            aria-describedby="inputGroupPrepend2"
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            onBlur={field.onBlur}
                          />
                        )}
                      />
                      <div className="d-flex flex-column justify-content-center bg-white p-0">
                        <span className="EyeIcon ps-2" onClick={handleConEye}>
                          {ConEyeState ? (
                            <img className="" alt="" src={Eye} />
                          ) : (
                            <img className="" alt="" src={EyeIcon} />
                          )}
                        </span>
                      </div>
                    </div>
                    <p
                      className="mb-0 text-danger mt-1"
                      style={{ height: "16px" }}
                    >
                      {errors?.ConPassword?.message}
                    </p>
                  </div>
                </div>
                <button
                  className="btn login-btn w-100 px-3 py-2 mt-3"
                  type="submit"
                >
                  Change Password
                  {isLoading && (
                    <div
                      className="spinner-border spinner-border-sm ms-3"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
