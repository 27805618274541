import React, { useContext, useState } from "react";
import banner from "../../assets/banner.png";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../routes/AuthProvider";

export default function OTP() {
  const navigate = useNavigate();
  const { otp: contextOtp } = useContext(AuthContext);
  const [otp, setOtp] = useState("");

  const [otpError, setOtpError] = useState("");
  const handleVerify = () => {
    // Send 'otp' to your server for validation
    // If validation is successful, proceed; otherwise, show an error message
    if (otp === contextOtp) {
      navigate("/change-password");
    } else {
      setOtpError("Incorrect OTP. Please try again.");
    }
  };

  return (
    <>
      <div className="container-fluid login-main">
        <div className="row h-100">
          <div className="col-md-6 d-md-block d-none h-100 login-image">
            <img className="banner" alt="" src={banner} />
          </div>
          <div className="col-md-6  login-content text-center">
            <div className="content-main h-100 px-5 d-flex flex-column justify-content-center">
              <div>
                <img
                  className="content-main-logo img-fluid"
                  alt=""
                  src={logo}
                />
              </div>
              <h2 className="text-white">OTP Verification</h2>
              <small className="otp-expire mb-2">
                Your OTP will expire in 60 seconds
              </small>
              <p className="text-white" style={{ fontWeight: "600" }}>
                Please enter OTP sent to your registered email.
              </p>
              {/* <form> */}
              <div className="form-row">
                <div className="col-12 text-start text-white mb-3 opt-parent">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    inputType="tel"
                    placeholder={"000000"}
                    renderInput={(props) => (
                      <input {...props} className="otp-input" />
                    )}
                  />
                </div>
                <p
                  className="text-danger mt-1"
                  // style={{ height: "16px" }}
                >
                  {otpError}
                </p>
              </div>
              <button
                className="btn login-btn w-100 px-3 py-2 mt-3 mb-2"
                // type="submit"
                onClick={handleVerify}
              >
                Verify OTP
              </button>
              <small className="text-white">
                I didn’t receive the code?
                <span>
                  <Link
                    to="/forgot-password"
                    className="text-decoration-none ps-2 resend-otp"
                  >
                    Resend OTP
                  </Link>
                </span>
              </small>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
