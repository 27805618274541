import React from 'react'
import Forgot from '../../components/forgot/Forgot'

export default function ForgotPage() {
  return (
    <>
    <Forgot/>
    </>
  )
}
