import React, { useState, useContext } from "react";
import banner from "../../assets/banner.png";
import logo from "../../assets/logo.png";
import envelope from "../../assets/envelope 1.png";
import Vector from "../../assets/Vector.png";
import EyeIcon from "../../assets/eye-blocked 1.svg";
import Eye from "../../assets/eye.svg";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthContext } from "../../routes/AuthProvider";
import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_API_URL;

export default function Login() {
  const { login, setFormData, formData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [EyeState, setEyeState] = useState(true);
  // const [loginData, setLoginData] = useState(null);
  const schema = Yup.object({
    email: Yup.string()
      .required("Email is required.")
      .email("Invalid email format"),
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password must contain 8 or more characters"),
  }).required();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: formData,
    resolver: yupResolver(schema),
  });
  const handleEye = () => {
    setEyeState(!EyeState);
  };
  const onSubmit = async (data) => {
    setFormData(data);
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/auth/login`, data);
      if (response?.data?.success === 1) {
        const token = response?.data?.result?.token;
        login(token);
        navigate("/dashboard");

        toast.success(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        navigate("/login-error", { state: { errorMessage: error?.response?.data?.message } });
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="container-fluid login-main">
        <div className="row h-100">
          <div className="col-md-6 d-md-block d-none h-100 login-image">
            <img className="banner" alt="" src={banner} />
          </div>
          <div className="col-md-6  login-content text-center">
            <div className="content-main h-100 px-5 d-flex flex-column justify-content-center">
              <div>
                <img
                  className="content-main-logo img-fluid"
                  alt=""
                  src={logo}
                />
              </div>
              <h2 className="text-white">Welcome Back</h2>
              <p className="text-white" style={{ fontWeight: "600" }}>
                Please login to access your account.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="col-12 text-start text-white mb-2">
                    <label className="" htmlFor="email">
                      Email
                    </label>
                    <div className="input-group px-3 py-1 bg-white rounded">
                      <div className="d-flex flex-column justify-content-center bg-white p-0">
                        <span className="pe-2" id="envelope-border">
                          <img className="p-0" alt="" src={envelope} />
                        </span>
                      </div>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            // defaultValue={formData?.email}
                            type="text"
                            className="form-control px-2"
                            id="email"
                            placeholder="Email"
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            onBlur={field.onBlur}
                          />
                        )}
                      />
                    </div>
                    <p
                      className="mb-0 text-danger mt-1"
                      style={{ height: "16px" }}
                    >
                      {errors?.email?.message}
                    </p>
                  </div>
                  <div className="col-12 text-start text-white mb-1">
                    <label className="" htmlFor="password">
                      Password
                    </label>
                    <div className="input-group px-3 py-1 bg-white rounded">
                      <div className="d-flex flex-column justify-content-center bg-white p-0">
                        <span className="pe-2" id="vectorBorder">
                          <img className="" alt="" src={Vector} />
                        </span>
                      </div>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            // defaultValue={formData?.password}
                            type={EyeState ? "password" : "text"}
                            className="form-control"
                            id="password"
                            placeholder="********"
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            onBlur={field.onBlur}
                          />
                        )}
                      />
                      <div className="d-flex flex-column justify-content-center bg-white p-0">
                        <span className="EyeIcon ps-2" onClick={handleEye}>
                          {EyeState ? (
                            <img className="" alt="" src={Eye} />
                          ) : (
                            <img className="" alt="" src={EyeIcon} />
                          )}
                        </span>
                      </div>
                    </div>
                    <p
                      className="mb-0 text-danger mt-1"
                      style={{ minHeight: "16px" }}
                    >
                      {errors?.password?.message}
                    </p>
                  </div>
                </div>
                <div className="form-group d-flex justify-content-between flex-wrap  mb-1">
                  <div className="form-check text-start">
                    <input
                      className="form-check-input largerCheckbox"
                      type="checkbox"
                      value=""
                      id="invalidCheck2"
                    />
                    <label
                      className="form-check-label text-black"
                      htmlFor="invalidCheck2"
                    >
                      Remember me
                    </label>
                  </div>
                  <Link className="text-decoration-none" to="/forgot-password">
                    <span className="text-end forget-password flex-grow-1">
                      Forgot Password?
                    </span>
                  </Link>
                </div>
                <button
                  className="btn login-btn w-100 px-3 py-2 mt-3"
                  type="submit"
                >
                  Login
                  {isLoading && (
                    <div
                      className="spinner-border spinner-border-sm ms-3"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
