import React from "react";
import OTP from "../../components/otp/OTP";

export default function OTPPage() {
  return (
    <>
      <OTP />
    </>
  );
}
