import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_API_URL;

export default function NameModal({
  showName,
  setShowName,
  editDate,
  handleSubmit,
  control,
  reset,
  errors,
  getProfileData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleNameClose = () => {
    setShowName(false);
    reset();
  };

  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    let body = data;
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${BASE_URL}/auth/update-profile`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.success === 1) {
        // setUserData(response?.data?.result);
        toast.success(response?.data?.message);
        getProfileData();
        setShowName(false);
      }
    } catch (error) {
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal centered show={showName} onHide={handleNameClose}>
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <p className="fw-semibold">Full Name *</p>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <input
                  defaultValue={editDate.name}
                  id="name"
                  type="text"
                  autoComplete="off"
                  className="form-control px-2 input-boxshadow-none"
                  placeholder="Full Name"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  onBlur={field.onBlur}
                />
              )}
            />
            <p className="text-danger py-2 mb-0">{errors?.name?.message}</p>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              type="submit"
              className="text-white btn"
              style={{ backgroundColor: "#336BA6" }}
              //   onClick={handleNameClose}
            >
              Save
              {isLoading && (
                <div
                  className="spinner-border spinner-border-sm ms-3"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
