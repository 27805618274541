import React from 'react'
import Status from '../../components/status/Status'

export default function StatusPage() {
  return (
    <>
    <Status/>
    </>
  )
}
