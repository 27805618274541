import React from 'react'
import PasswordUpdate from '../../components/password-update/PasswordUpdate'

export default function PasswordUpdatePage() {
  return (
    <>
        <PasswordUpdate/>
    </>
  )
}
