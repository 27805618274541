import React, { useContext, useState } from "react";
import banner from "../../assets/banner.png";
import logo from "../../assets/logo.png";
import envelope from "../../assets/envelope 1.png";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../routes/AuthProvider";
const BASE_URL = process.env.REACT_APP_API_URL;

export default function Forgot() {
  const { otphandler, setEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const schema = Yup.object({
    email: Yup.string()
      .required("Email is required.")
      .email("Invalid email format"),
  }).required();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/auth/send-otp`, data);
      if (response?.data?.success === 0) {
        const otpCode = response?.data?.result?.otp;
        otphandler(otpCode);
        setEmail(data?.email);
        toast.success(response?.data?.message);
        navigate("/otp");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid login-main">
        <div className="row h-100">
          <div className="col-md-6 d-md-block d-none h-100 login-image">
            <img className="banner" alt="" src={banner} />
          </div>
          <div className="col-md-6  login-content text-center">
            <div className="content-main h-100 px-5 d-flex flex-column justify-content-center">
              <div>
                <img
                  className="content-main-logo img-fluid"
                  alt=""
                  src={logo}
                />
              </div>
              <h2 className="text-white">Forgot Password</h2>
              <p className="text-white px-sm-5" style={{ fontWeight: "600" }}>
                Please enter your registered email address to receive a
                verification code.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="col-12 text-start text-white mb-3">
                    <label className="" htmlFor="email">
                      Email
                    </label>
                    <div className="input-group px-3 py-1 bg-white rounded">
                      <div className="d-flex flex-column justify-content-center bg-white p-0">
                        <span className="pe-2" id="envelope-border">
                          <img className="p-0" alt="" src={envelope} />
                        </span>
                      </div>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control px-2"
                            id="email"
                            placeholder="Email"
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            onBlur={field.onBlur}
                          />
                        )}
                      />
                    </div>
                    <p
                      className="mb-0 text-danger mt-1"
                      style={{ height: "16px" }}
                    >
                      {errors?.email?.message}
                    </p>
                  </div>
                </div>
                <div className="form-group d-flex justify-content-end flex-wrap  mb-1">
                  <Link className="text-decoration-none" to="/">
                    <span className="text-end forget-password flex-grow-1">
                      Login
                    </span>
                  </Link>
                </div>
                <button
                  className="btn login-btn w-100 px-3 py-2 mt-3"
                  type="submit"
                >
                  Send OTP
                  {isLoading && (
                    <div
                      className="spinner-border spinner-border-sm ms-3"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
