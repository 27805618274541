import React from 'react'
import Dashboard from '../../components/dashboard/MainDashboard'

export default function DashboardPage() {
  return (
    <>
    <Dashboard/>
    </>
  )
}
