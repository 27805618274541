import React from "react";
import banner from "../../assets/banner.png";
import logo from "../../assets/logo.png";
import Update from "../../assets/update.png";
import { Link } from "react-router-dom";

export default function PasswordUpdate() {
  return (
    <>
      <div className="container-fluid login-main">
        <div className="row h-100">
          <div className="col-md-6 d-md-block d-none h-100 login-image">
            <img className="banner" alt="" src={banner} />
          </div>
          <div className="col-md-6  login-content text-center">
            <div className="content-main h-100 px-5  d-flex flex-column justify-content-center">
              <div>
                <img
                  className="content-main-logo img-fluid"
                  alt=""
                  src={logo}
                />
              </div>
              <div className="py-2">
                <img
                  className="content-main-logo img-fluid"
                  alt=""
                  src={Update}
                />
              </div>
              <h2 className="text-white">Password Updated</h2>
              <p className="text-white">Your password has been updated.</p>
              <Link className="text-text-decoration-none" to="/">
                <button className="btn login-btn w-100 px-3 py-2 mt-3">
                  Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
