import React from 'react'
import LoginError from '../../components/login-error/LoginError'

export default function LoginErrorPage() {
  return (
    <>
        <LoginError/>
    </>
  )
}
