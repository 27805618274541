import React from "react";
import GraphData from "../../components/graph/GraphData";

export default function GraphDataPage() {
  return (
    <>
      <GraphData />
    </>
  );
}
