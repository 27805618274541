import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import BackArrow from "../../assets/back-button.png";
import ThreeDot from "../../assets/three-dot.png";
import { Link, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../common/Spinner";
import StatusModal from "./modals/StatusModal";
import EditStatus from "./modals/EditStatus";
const BASE_URL = process.env.REACT_APP_API_URL;

export default function Status() {
  const [statusData, setStatusData] = useState(null);
  const navigate = useNavigate();
  const [searchNameText, setSearchNameText] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getStatusData();
    // eslint-disable-next-line
  }, [searchNameText]);

  const getStatusData = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/get-all-status${
          searchNameText ? `?name=${searchNameText}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.success === 1) {
        setStatusData(response?.data?.status);
        // toast.success(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchName = (e) => {
    setSearchNameText(e.target.value);
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    let body = {
      status_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // setIsLoading(true);
        try {
          const response = await axios.delete(`${BASE_URL}/delete-status`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: body,
          });
          if (response?.data?.success === 1) {
            Swal.fire({
              title: "Deleted!",
              text: response?.data?.message,
              icon: "success",
              confirmButtonColor: "#336ba6",
            });
            getStatusData();
          }
        } catch (error) {
          if (error?.response?.data) {
            if (
              error.response.data.message ===
              "Session expired please login again"
            ) {
              // Handle session expiration
              localStorage.removeItem("token");
              navigate("/");
              toast.error(error?.response?.data?.message);
            } else {
              toast.error(error?.response?.data?.message);
            }
          } else {
            toast.error("Something went wrong");
          }
        }
      }
    });
  };

  return (
    <div>
      <Navbar
        handleSearchName={handleSearchName}
        searchNameText={searchNameText}
      />
      <div className="px-3 py-3">
        <div>
          <Link to="/dashboard">
            <img alt="" src={BackArrow} />
          </Link>
        </div>
        <div className="ms-md-5 ps-md-4 pt-4 ">
          <h4 className="mb-3">All Status</h4>
          <StatusModal getStatusData={getStatusData} />
          <div className="container-fluid">
            <div className="row gap-3 justify-content-center">
              {!isLoading &&
                (statusData && statusData.length > 0 ? (
                  statusData?.map((item) => {
                    return (
                      <div
                        style={{
                          backgroundColor: `${item?.status_color}`,
                        }}
                        className={` col-sm-8 col-12 border rounded px-2 `}
                        key={item?._id}
                      >
                        <div className="d-flex justify-content-between flex-wrap align-items-center px-3">
                          <h6
                          //removed text-white class from below (client's changes)
                            className=" mb-0  py-2"
                            style={{ fontSize: "18px" }}
                          >
                            {item?.status_name}
                          </h6>
                          <div className="d-flex justify-content-end flex-grow-1 py-2 ms-2">
                            <div
                              className="dropdown  bg-white rounded px-2 py-1 d-flex justify-content-between align-items-center"
                              style={{ width: "132px" }}
                            >
                              {item?.is_status_active ? (
                                <span className="fw-semibold">
                                  <span className="dot-active me-2"></span>
                                  Active
                                </span>
                              ) : (
                                <span className=" fw-semibold">
                                  <span className="dot-in-active  me-2"></span>
                                  In-Active
                                </span>
                              )}
                              <span
                                className="fw-semibold pointer"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img className="ms-4" alt="" src={ThreeDot} />
                              </span>
                              <ul className="dropdown-menu three-dot-menu-status">
                                <EditStatus
                                  editData={item}
                                  getStatusData={getStatusData}
                                />
                                <li
                                  className="p-1 border-bottom "
                                  onClick={() => {
                                    handleDelete(item?._id);
                                  }}
                                >
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center mt-5 fw-bold">No data found.</div>
                ))}
              {isLoading && <Spinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
