import React, { useState } from "react";
import EyeIcon from "../../assets/eye-blocked 1.svg";
import Eye from "../../assets/eye.svg";
// import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PlusIcon from "../../assets/plus-icon.png";
import Modal from "react-bootstrap/Modal";
import Select, { components } from "react-select";
import AdminIcon from "../../assets/admin.svg";
import AgentIcon from "../../assets/agent.svg";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_API_URL;

const { Option } = components;

export default function AddUsers({ getUserData }) {
  const navigate = useNavigate();

  const today = dayjs();
  const formattedToday = today.format("MM-DD-YYYY");
  //   const navigate = useNavigate();
  const [EyeState, setEyeState] = useState(true);
  const [ConEyeState, setConEyeState] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const schema = Yup.object({
    name: Yup.string().required("Name is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Invalid email format"),
    role: Yup.string().required("Role is required."),
    status: Yup.string().required("Status is required."),
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password must contain 8 or more characters"),
    ConPassword: Yup.string()
      .required("Confirm Password is required.")
      .min(8, "Confirm Password must contain 8 or more characters")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  }).required();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      role: "Agent",
      status: "Active",
      bg_color: "#D96D6D",
    },
  });
  const handleEye = () => {
    setEyeState(!EyeState);
  };
  const handleConEye = () => {
    setConEyeState(!ConEyeState);
  };
  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    let body = {
      name: data?.name,
      email: data?.email,
      role: data?.role,
      is_active: data?.status === "Active" ? true : false,
      password: data?.password,
      confirm_password: data?.ConPassword,
      joining_date: formattedToday,
      bg_color:data?.bg_color
    };
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/auth/create-user`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.success === 1) {
        // setUserData(response?.data?.result);
        toast.success(response?.data?.message);
        getUserData();
        setShow(false);
      }
    } catch (error) {
      if (error?.response?.data) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => setShow(true);
  const options = [
    { value: "Active", label: "Active", icon: true },
    { value: "In-Active", label: "In-Active", icon: false },
  ];
  const Roleoptions = [
    { value: "Agent", label: "Agent", icon: false },
    { value: "Admin", label: "Admin", icon: true },
  ];
  return (
    <>
      {/* <!-- Button trigger modal --> */}
      <div className="text-end mb-4">
        <button className="add-user-button" onClick={handleShow}>
          <span className="me-2">
            <img alt="" src={PlusIcon} />
          </span>
          Add User
        </button>
      </div>
      {/* <!-- Modal --> */}
      <Modal centered size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-3 fw-semibold w-100 text-center">
            {" "}
            Add User
          </Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="form-row row">
              <div className="col-md-6 text-start mb-0">
                <label htmlFor="name">Name</label>
                <div className="input-group py-1 bg-white rounded">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <input
                        id="name"
                        type="text"
                        autoComplete="off"
                        className="form-control px-2 input-boxshadow-none"
                        placeholder="Name"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                </div>
                <p className="text-danger py-2 mb-0">{errors?.name?.message}</p>
              </div>
              <div className="col-md-6 text-start mb-0">
                <label htmlFor="user-email">Email</label>
                <div className="input-group py-1 bg-white rounded">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <input
                        id="user-email"
                        type="text"
                        className="form-control px-2 input-boxshadow-none"
                        placeholder="Email"
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                </div>
                <p className="text-danger py-2 mb-0">
                  {errors?.email?.message}
                </p>
              </div>
              <div className="col-md-6 text-start mb-0">
                <p className="mb-0">Role</p>
                <div className="input-group py-1 bg-white rounded">
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <Select
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        defaultValue={Roleoptions[0]}
                        onBlur={field.onBlur}
                        styles={{
                          control: (base) => ({
                            ...base,
                            boxShadow: "none",
                          }),
                        }}
                        isSearchable={false}
                        className="w-100"
                        options={Roleoptions}
                        components={{
                          IndicatorSeparator: () => null,
                          Option: (props) => (
                            <Option {...props}>
                              {props.data.icon ? (
                                <span className="me-1">
                                  <img alt="" src={AdminIcon} />
                                </span>
                              ) : (
                                <span className="me-1">
                                  <img alt="" src={AgentIcon} />
                                </span>
                              )}
                              {props.data.label}
                            </Option>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <p className="text-danger py-2 mb-0">{errors?.role?.message}</p>
              </div>
              <div className="col-md-6 text-start mb-0">
                <p className="mb-0">Status</p>
                <div className="input-group py-1 bg-white rounded">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        defaultValue={options[0]}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        onBlur={field.onBlur}
                        styles={{
                          control: (base) => ({
                            ...base,
                            boxShadow: "none",
                          }),
                        }}
                        isSearchable={false}
                        className="w-100"
                        options={options}
                        components={{
                          IndicatorSeparator: () => null,
                          Option: (props) => (
                            <Option {...props}>
                              {props.data.icon ? (
                                <span className="dot-active me-1"></span>
                              ) : (
                                <span className="dot-in-active me-1"></span>
                              )}
                              {props.data.label}
                            </Option>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <p className="text-danger py-2 mb-0">
                  {errors?.status?.message}
                </p>
              </div>

              <div className="col-md-6 text-start mb-0">
                <label className="" htmlFor="user-password">
                  Password
                </label>
                <div className="py-1">
                  <div className="input-group border rounded ">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <input
                          id="user-password"
                          type={EyeState ? "password" : "text"}
                          className="form-control input-boxshadow-none border-0 pe-0"
                          placeholder="********"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          onBlur={field.onBlur}
                        />
                      )}
                    />
                    <div className="d-flex flex-column justify-content-center bg-white p-0">
                      <span className="EyeIcon px-2" onClick={handleEye}>
                        {EyeState ? (
                          <img className="" alt="" src={Eye} />
                        ) : (
                          <img className="" alt="" src={EyeIcon} />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-danger py-2 mb-0">
                  {errors?.password?.message}
                </p>
              </div>

              <div className="col-md-6 text-start mb-0">
                <label className="" htmlFor="user-confirm-password">
                  Confirm Password
                </label>
                <div className="py-1">
                  <div className="input-group border rounded ">
                    <Controller
                      name="ConPassword"
                      control={control}
                      render={({ field }) => (
                        <input
                          id="user-confirm-password"
                          type={ConEyeState ? "password" : "text"}
                          className="form-control input-boxshadow-none border-0 pe-0"
                          placeholder="********"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          onBlur={field.onBlur}
                        />
                      )}
                    />
                    <div className="d-flex flex-column justify-content-center bg-white p-0">
                      <span className="EyeIcon px-2" onClick={handleConEye}>
                        {ConEyeState ? (
                          <img className="" alt="" src={Eye} />
                        ) : (
                          <img className="" alt="" src={EyeIcon} />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <p className="text-danger py-2 mb-0">
                  {errors?.ConPassword?.message}
                </p>
              </div>
              <div className="col-md-6 text-start mb-0">
                <label className="" htmlFor="bg_color">
                  Background Color
                </label>
                <div className="py-1">
                  <div className="input-group border rounded ">
                    <Controller
                      name="bg_color"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          id="bg_color"
                          type="color"
                          autoComplete="off"
                          className="form-control px-2 input-boxshadow-none"
                          style={{ height: "38px" }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          onBlur={field.onBlur}
                        />
                      )}
                    />
                  </div>
                </div>
                <p className="text-danger py-2 mb-0">
                  {errors?.bg_color?.message}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-secondary"
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save
              {isLoading && (
                <div
                  className="spinner-border spinner-border-sm ms-3"
                  role="status"
                >
                  <span className="sr-only"></span>
                </div>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
