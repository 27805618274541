import React, { useContext, useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import BackArrow from "../../assets/back-button.png";
import { Link, useNavigate } from "react-router-dom";
import MyProfileImg from "../../assets/profile-img.png";
import EditTip from "../../assets/edit-tip.png";
import NameModal from "./modals/NameModal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
// import EmailModal from "./modals/EmailModal";
import PhoneModal from "./modals/PhoneModal";
import PasswordModal from "./modals/PasswordModal";
import CityModal from "./modals/CityModal";
// import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../common/Spinner";
import { AuthContext } from "../../routes/AuthProvider";

const BASE_URL = process.env.REACT_APP_API_URL;

export default function MyProfile() {
  const navigate = useNavigate();
  const { updateProfileData } = useContext(AuthContext);

  const [showName, setShowName] = useState(false);
  // const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);

  const [editDate, setEditData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
  });
  // console.log(editDate, "editDate");
  const Nameschema = Yup.object({
    name: Yup.string().required("Full Name is required."),
  }).required();

  // const Emailschema = Yup.object({
  //   email: Yup.string()
  //     .required("Email is required.")
  //     .email("Invalid email format"),
  // }).required();

  // const Phoneschema = Yup.object({
  //   phone: Yup.string()
  //     .required("Email is required.")
  //     .email("Invalid email format"),
  // }).required();

  const Passwordschema = Yup.object({
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password must contain 8 or more characters"),
    NewPassword: Yup.string()
      .required("New Password is required.")
      .min(8, "New Password must contain 8 or more characters")
      .notOneOf([Yup.ref("password"), null], "Can not use current password"),
    ConPassword: Yup.string()
      .required("Confirm Password is required.")
      .min(8, "Confirm Password must contain 8 or more characters")
      .oneOf([Yup.ref("NewPassword"), null], "Passwords must match"),
  }).required();

  const Cityschema = Yup.object({
    city: Yup.string().required("City is required."),
  }).required();

  const {
    handleSubmit: handleSubmitName,
    control: controlName,
    reset: resetName,
    setValue: setValueName,
    formState: { errors: errorsName },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Nameschema),
  });

  // const {
  //   handleSubmit: handleSubmitEmail,
  //   control: controlEmail,
  //   reset: resetEmail,
  //   setValue: setValueEmail,
  //   formState: { errors: errorsEmail },
  // } = useForm({
  //   mode: "all",
  //   resolver: yupResolver(Emailschema),
  // });

  const {
    handleSubmit: handleSubmitPhone,
    control: controlPhone,
    reset: resetPhone,
    setValue: setValuePhone,
    setError: setPhoneError,
    formState: { errors: errorsPhone },
  } = useForm({
    mode: "all",
    // resolver: yupResolver(Phoneschema),
  });

  const {
    handleSubmit: handleSubmitPassword,
    control: controlPassword,
    reset: resetPassword,
    // setValue: setValuePassword,
    // setError:setPhoneError,
    formState: { errors: errorsPassword },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Passwordschema),
  });

  const {
    handleSubmit: handleSubmitCity,
    control: controlCity,
    reset: resetCity,
    setValue: setValueCity,
    formState: { errors: errorsCity },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Cityschema),
  });

  const handleNameShow = (data, test) => {
    setEditData({ ...editDate, [test]: data });
    setValueName(test, data);
    setShowName(true);
  };

  // const handleEmailShow = (data, test) => {
  //   setEditData({ ...editDate, [test]: data });
  //   setValueEmail(test, data);
  //   setShowEmail(true);
  // };

  const handlePhoneShow = (data, test) => {
    setEditData({ ...editDate, [test]: data });
    setValuePhone(test, data);
    setShowPhone(true);
  };

  const handleCityShow = (data, test) => {
    setEditData({ ...editDate, [test]: data });
    setValueCity(test, data);
    setShowCity(true);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedMimeTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
      ];
      if (!allowedMimeTypes.includes(file.type)) {
        toast.error("Invalid file type. Only image files are allowed.");
        e.target.value = "";
        return;
      }
      const formData = new FormData();
      formData.append("profilePicture", file);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.put(
          `${BASE_URL}/auth/update-profile`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response?.data?.success === 1) {
          toast.success(response?.data?.message);
          getProfileData();
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          if (
            error.response.data.message === "Session expired please login again"
          ) {
            // Handle session expiration
            localStorage.removeItem("token");
            navigate("/");
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error?.response?.data?.message);
          }
        } else {
          toast.error("Something went wrong");
        }
      } finally {
        // setIsLoading(false);
      }

      // You may want to add additional checks here, such as file type validation
      // setProfileImage(URL.createObjectURL(file));
    }
  };
  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfileData = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/auth/get-my-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.success === 1) {
        setProfileData(response?.data?.user);
        updateProfileData(response?.data?.user);
        // toast.success(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        if (
          error.response.data.message === "Session expired please login again"
        ) {
          // Handle session expiration
          localStorage.removeItem("token");
          navigate("/");
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Navbar />

      <div className="px-3 py-3">
        <div>
          <Link to={"/registered-users"}>
            <img alt="" src={BackArrow} />
          </Link>
        </div>
        <div
          className="container border rounded p-3 mt-3"
          style={{ maxWidth: "950px", color: "#505050" }}
        >
          <div className="row" style={{ margin: "1px" }}>
            <h3 className="text-center fw-semibold">My Profile</h3>
            {isLoading && <Spinner />}
            {!isLoading && profileData && (
              <>
                <div className="d-sm-flex align-items-center">
                  <label htmlFor="profile-image-upload">
                    <div
                      className="position-relative rounded-circle overflow-hidden pointer"
                      style={{
                        width: "120px",
                        height: "120px",
                        border: "5px solid #E1EDEE",
                      }}
                    >
                      <img
                        alt=""
                        src={profileData?.profileUrl || MyProfileImg}
                        className="w-100 h-100 p-0"
                        style={{ objectFit: "cover", objectPosition: "center" }}
                      />
                      <img
                        alt=""
                        src={EditTip}
                        className="position-absolute p-0"
                        style={{ right: "8px", bottom: "8px" }}
                      />
                    </div>
                    <input
                      id="profile-image-upload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  </label>

                  <p className="mb-0 ms-sm-3 ms-0 mt-sm-0 mt-3 fw-semibold">
                    {profileData?.name}
                  </p>
                </div>
                <div className="row mx-0 px-0">
                  <div className="col-lg-6 mt-4 ">
                    <div className="row border p-1 me-lg-1">
                      <div className="col-sm-5 p-2 fw-semibold">Name</div>
                      <div
                        className="col-sm-7 p-2 d-flex justify-content-between flex-wrap pointer"
                        onClick={() =>
                          handleNameShow(profileData?.name, "name")
                        }
                        style={{ backgroundColor: "#E1EDEE" }}
                      >
                        <span style={{ color: "#8E8E8E" }}>
                          {profileData?.name}
                        </span>
                        <span
                          className="fw-semibold"
                          style={{ color: "#9CC1C5" }}
                        >
                          Change
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4 ">
                    <div className="row border p-1 ms-lg-1">
                      <div className="col-sm-5 p-2 fw-semibold">Email</div>
                      <div
                        className="col-sm-7 p-2 d-flex justify-content-between flex-wrap "
                        // onClick={() =>
                        //   handleEmailShow(profileData?.email, "email")
                        // }
                        style={{ backgroundColor: "#E1EDEE" }}
                      >
                        <span style={{ color: "#8E8E8E" }}>
                          {profileData?.email}
                        </span>
                        {/* <span
                          className="fw-semibold"
                          style={{ color: "#9CC1C5" }}
                        >
                          Change
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4 ">
                    <div className="row border p-1 me-lg-1">
                      <div className="col-sm-5 p-2 fw-semibold">Phone</div>
                      <div
                        className="col-sm-7 p-2 d-flex justify-content-between flex-wrap pointer"
                        onClick={() =>
                          handlePhoneShow(profileData?.phone, "phone")
                        }
                        style={{ backgroundColor: "#E1EDEE" }}
                      >
                        <span style={{ color: "#8E8E8E" }}>
                          {profileData?.phone}
                        </span>
                        <span
                          className="fw-semibold"
                          style={{ color: "#9CC1C5" }}
                        >
                          Change
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4 ">
                    <div className="row border p-1 ms-lg-1">
                      <div className="col-sm-5 p-2 fw-semibold">City</div>
                      <div
                        className="col-sm-7 p-2 d-flex justify-content-between flex-wrap pointer"
                        onClick={() =>
                          handleCityShow(profileData?.city, "city")
                        }
                        style={{ backgroundColor: "#E1EDEE" }}
                      >
                        <span style={{ color: "#8E8E8E" }}>
                          {profileData?.city ? profileData?.city : "Not Yet"}
                        </span>
                        <span
                          className="fw-semibold"
                          style={{ color: "#9CC1C5" }}
                        >
                          Change
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4 ">
                    <div className="row border p-1 me-lg-1">
                      <div className="col-sm-5 p-2 fw-semibold">
                        Joining Date
                      </div>
                      <div
                        className="col-sm-7 p-2 d-flex justify-content-between flex-wrap pointer"
                        style={{ backgroundColor: "#E1EDEE" }}
                      >
                        <span style={{ color: "#8E8E8E" }}>
                          {profileData?.joining_date}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4 ">
                    <div className="row border p-1 ms-lg-1">
                      <div className="col-sm-5 p-2 fw-semibold">Password</div>
                      <div
                        className="col-sm-7 p-2 d-flex justify-content-between flex-wrap pointer"
                        onClick={() => setShowPassword(true)}
                        style={{ backgroundColor: "#E1EDEE" }}
                      >
                        <span style={{ color: "#8E8E8E" }} className="fw-bold">
                          &bull; &bull; &bull; &bull; &bull; &bull;
                        </span>
                        <span
                          className="fw-semibold"
                          style={{ color: "#9CC1C5" }}
                        >
                          Change
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/*------------------------- modals-------------------- */}
      <NameModal
        showName={showName}
        setShowName={setShowName}
        editDate={editDate}
        handleSubmit={handleSubmitName}
        control={controlName}
        reset={resetName}
        errors={errorsName}
        getProfileData={getProfileData}
      />
      {/* <EmailModal
        showEmail={showEmail}
        setShowEmail={setShowEmail}
        editDate={editDate}
        handleSubmit={handleSubmitEmail}
        control={controlEmail}
        reset={resetEmail}
        errors={errorsEmail}
        getProfileData={getProfileData}
      /> */}
      <PhoneModal
        showPhone={showPhone}
        setShowPhone={setShowPhone}
        editDate={editDate}
        setEditData={setEditData}
        handleSubmit={handleSubmitPhone}
        control={controlPhone}
        reset={resetPhone}
        errors={errorsPhone}
        setPhoneError={setPhoneError}
        getProfileData={getProfileData}
      />
      <PasswordModal
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        // editDate={editDate}
        handleSubmit={handleSubmitPassword}
        control={controlPassword}
        reset={resetPassword}
        errors={errorsPassword}
        getProfileData={getProfileData}
      />
      <CityModal
        showCity={showCity}
        setShowCity={setShowCity}
        editDate={editDate}
        handleSubmit={handleSubmitCity}
        control={controlCity}
        reset={resetCity}
        errors={errorsCity}
        getProfileData={getProfileData}
      />
    </div>
  );
}
