import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

export default function Graph({ DayData, Daycolor }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom);

    const option = {
      //   title: {
      //     text: 'Referer of a Website',
      //     subtext: 'Fake Data',
      //     left: 'center'
      //   },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'horizontal',
        bottom: 10,
        type: "scroll",
        // itemWidth: 15, // Set the width of the legend item
        // itemHeight: 15, // Set the height of the legend item
      },
      color: Daycolor,

      series: [
        {
          //   name: 'Access From',
          type: 'pie',
          radius: ['5%', '50%'],
          startAngle: 50,
          avoidLabelOverlap: false,
          label: {
            formatter: '{c}', // Show value on polyline
            // position: 'inside', // Position of the value label
          },
          //   top:0,
          itemStyle: {
            borderColor: 'white', // Set the color of the dividers
            borderWidth: 2,        // Set the width of the dividers
          },
          data: DayData,
          //   emphasis: {
          //     itemStyle: {
          //       shadowBlur: 10,
          //       shadowOffsetX: 0,
          //       shadowColor: 'rgba(0, 0, 0, 0.5)'
          //     }
          //   }
        }
      ]
    };

    myChart.setOption(option);

    // Resize chart when window is resized
    const resizeHandler = () => {
      myChart.resize();
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      myChart.dispose();
      window.removeEventListener('resize', resizeHandler);
    };
  }, [DayData, Daycolor]);

  return (
    <div id="main border" ref={chartRef} style={{ width: '100%', height: '300px' }}></div>
  );
};


